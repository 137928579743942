'use strict';

const Newsletter = {
    el: null,
    init: function () {
        Newsletter.els = document.querySelectorAll('.f-newsletter');
        Newsletter.url = api?.newsletter;
        console.log(Newsletter.els, Newsletter.url)
        if (Newsletter.els.length > 0 && Newsletter.url) {
            Newsletter.els.forEach(newsletter => {

                let form = newsletter.querySelector("form");

                if (form) {
                    form.captcha = form.querySelector("[data-sitekey]");
                    form.errors = newsletter.querySelector(".f-newsletter__error");

                    form.addEventListener("submit", e => {
                        e.preventDefault();

                        form.errors.innerHTML = "";
                        form.querySelectorAll("[name]").forEach(field => field.classList.remove("error"))

                        let formData = new FormData(form);

                        fetch(Newsletter.url,
                            {
                                method: "POST",
                                body: formData
                            }
                        )
                            .then(response => response.json())
                            .then(json => {
                                if (json.data?.status === 200) {
                                    newsletter.classList.add("f-newsletter--completed");
                                    newsletter.querySelector('.f-newsletter__file__texte').innerHTML = json.data.msg;
                                }
                                else if (json.code === "error_validation") {
                                    if (form.captcha) {
                                        form.captcha.friendlyChallengeWidget.reset();

                                        for(var err in json.data.errors){
                                            form.querySelector("[name='" + err + "']").classList.add("error");
                                            form.errors.innerHTML += "-" + json.data.errors[err] + "<br>";
                                        }
                                    }
                                }
                            });
                    })
                }
            })
        }
    }
};

export default Newsletter;