import Header from "./components/Header";
import SliderSingle from "./blocks/slider_single";
import SliderMulti from "./blocks/slider_multi";
import Video from "./blocks/video";
import Accordion from "./blocks/accordion";
import Select from "./components/Select";
import Tab from "./blocks/tab";
import InteractiveImage from "./blocks/interactiveimage";
import Footer from "./components/Footer";
import Gated from "./components/Gated";
import Newsletter from "./components/Newsletter";
import Contact from "./components/Contact";
import News from "./components/News";
import Animation from "./animations/_main";
import Offices from "./components/Offices";
import Wysiwyg from "./blocks/wysiwyg";
import Menu from "./components/Menu";


const main = {
    init: function() {
        Tab.init();
        Wysiwyg.init();

        Animation.init();
        
        Offices.init();
        Header.init();
        Footer.init();
        SliderSingle.init();
        SliderMulti.init();
        Video.init();
        Accordion.init();
        InteractiveImage.init();
        Gated.init();
        Newsletter.init();
        Contact.init();
        Select.init();
        News.init();
        Menu.init();
    }
};

export default main