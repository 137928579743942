'use strict';

import BorderScale from "./borderScale";
import FadeSlideIn from "./fadeSlideIn";
import FadeSlideInChildren from "./fadeSlideInChildren";
import ImagePath from "./ImagePath";
import IncrementNumber from "./IncrementNumber";
import ScrollManager from "./scrollManager";
import SplitTexte from "./SplitTexte";

const Animation = {
    html: null,
    init: function () {
        Animation.html = document.querySelector("html");

        if (!Animation.html.classList.contains("noAnim")) {
            Animation.html.classList.add("withAnim");
        }


        let flexibles = document.querySelectorAll(".f:not(.f-offices), .main__header, .t-news__filtre, .t-work__item, .t-contact__cols, .f-offices__col--search, .f-offices__col--search2, .f-hero__box, .f-herohome__box");

        let animations = ".split-texte, .image-path, .fade-slide-in, .fade-slide-in-children, .border-scale, .increment-number";

        flexibles.forEach(flex => {
            let blocs = flex.querySelectorAll(animations);
            let index = 0;

            blocs.forEach(bloc => {
                if (bloc.classList.contains("split-texte")) {
                    index = SplitTexte.init(bloc, index);
                } else if (bloc.classList.contains("fade-slide-in")) {
                    index = FadeSlideIn.init(bloc, index);
                } else if (bloc.classList.contains("fade-slide-in-children")) {
                    index = FadeSlideInChildren.init(bloc, index);
                } else if (bloc.classList.contains("image-path")) {
                    index = ImagePath.init(bloc, index);
                } else if (bloc.classList.contains("border-scale")) {
                    index = BorderScale.init(bloc, index);
                } else if (bloc.classList.contains("increment-number")) {
                    index = IncrementNumber.init(bloc, index);
                }
            })
        })

        ScrollManager.init(flexibles);
        Animation.html.classList.add("animReady");
    },
};

export default Animation;
