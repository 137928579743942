'use strict';

const Gated = {
    el: null,
    init: function () {
        Gated.els = document.querySelectorAll('.f-gated');
        Gated.url = api?.gated_content;

        if (Gated.els.length > 0 && Gated.url) {
            Gated.els.forEach(gated => {

                let form = gated.querySelector("form");

                if (form) {

                    form.captcha = form.querySelector("[data-sitekey]");
                    form.texte = gated.querySelector(".f-gated__file__texte");
                    form.cta = gated.querySelector(".f-gated__file__cta");
                    form.cta2 = gated.querySelector(".f-gated__file__cta2");
                    form.errors = gated.querySelector(".f-gated__error");

                    if (form.texte && form.cta && form.cta2) {

                        form.addEventListener("submit", e => {
                            e.preventDefault();

                            form.errors.innerHTML = "";
                            form.querySelectorAll("[name]").forEach(field => field.classList.remove("error"))

                            let formData = new FormData(form);

                            fetch(Gated.url,
                                {
                                    method: "POST",
                                    body: formData
                                }
                            )
                                .then(response => response.json())
                                .then(json => {
                                    if (json.data?.status === 200) {
                                        gated.classList.add("f-gated--completed");
                                        let gatedfile = json.data?.gated_file;
                                        form.texte.innerHTML = gatedfile?.thx_msg;
                                        form.cta.innerHTML = '<a title="Download the document ' + gatedfile?.filename + ' (same window)" href="' + gatedfile?.link + '"><span>Download</a>';
                                        form.cta2.innerHTML = '<a title="Download the document ' + gatedfile?.filename + ' (same window)" href="' + gatedfile?.link + '">' + gatedfile?.filename + '<br>' + gatedfile?.filesize + '</a>';
                                    }
                                    else if (json.code === "error_validation") {
                                        if (form.captcha) {
                                            form.captcha.friendlyChallengeWidget.reset();

                                            for(var err in json.data.errors){
                                                form.querySelector("[name='" + err + "']").classList.add("error");
                                                form.errors.innerHTML += "-" + json.data.errors[err] + "<br>";
                                            }
                                        }
                                    }
                                });
                        })
                    }
                }
            })
        }
    }
};

export default Gated;