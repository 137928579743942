class Menu{
    static init(){
        this.header = document.querySelector(".header");
        this.lastScroll = document.documentElement.scrollTop;
        if(this.header){
            document.addEventListener("scroll", (e) => {
                this.tempScroll = document.documentElement.scrollTop;
                if(this.lastScroll < this.tempScroll){
                    this.header.classList.add("header--hidden");
                } else {
                    this.header.classList.remove("header--hidden");
                }
                this.lastScroll = this.tempScroll
            })
        }
    }
}

export default Menu;