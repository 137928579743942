'use strict';

const IncrementNumber = {
    init: function (elem, index) {
        elem.index = index;
        elem.classList.add("anim-delay" + index++)

        elem.save = elem.innerHTML;
        elem.needAnim = true;

        this.render(elem);

        elem.addEventListener("transitionend", e => {

            elem.needAnim = false;
            elem.classList.remove("anim-delay" + elem.index)

        })

        return index;
    },

    isDigit(char) {
        return (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(char) > -1);
    },

    render(elem) {
        if (elem.needAnim) {
            let temp = "";
            for (let i = 0; i < elem.save.length; i++) {
                temp += this.isDigit(elem.save[i]) ? Math.floor(10 * Math.random()) : elem.save[i];
            }

            elem.innerHTML = temp;

            requestAnimationFrame(() => this.render(elem));
        } else {
            elem.innerHTML = elem.save;
        }

    }
};

export default IncrementNumber;