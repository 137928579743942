'use strict';

const Header = {
    el: null,
    toggle: null,
    currentSubMenu: null,
    init: function () {
        Header.el = document.querySelector('.header');
        Header.body = document.querySelector('body');
        Header.headerNav = document.querySelector('.header__nav')
        Header.main = document.querySelector('main');
        Header.footer = document.querySelector('footer');
        Header.mainmenu = document.querySelector('.menu-main-menu-container');
        Header.menus = document.querySelectorAll('.menu-item-has-children');
        Header.buttons = document.querySelectorAll('.menu-item-has-children > a');
        Header.mainButtons = document.querySelectorAll('.menu > li > a');

        Header.navFocusable = Header.headerNav.querySelectorAll("a, button, select, input, textarea, iframe");
        Header.navFocusable.forEach(item => item.tabIndex = "-1")
        
        Header.subNavFocusable = Header.headerNav.querySelectorAll(".header__box a");
        Header.subNavFocusable.forEach(item => item.tabIndex = "-1")

        Header.footerFocusable = Header.footer.querySelectorAll("a, button, select, input, textarea, iframe, video");

        if (Header.el && Header.mainmenu) {
            Header.toggle = Header.el.querySelector('.burger-menu');
            if (Header.toggle) {
                Header.toggle.addEventListener('click', () => {
                    Header.toggle.classList.toggle('open--');
                    Header.el.classList.toggle('open--');
                    Header.body.classList.toggle('open--');

                    Header.mainFocusable = Header.main.querySelectorAll("a, button, select, input, textarea, iframe, video, .custom-select-opener");

                    if(Header.body.classList.contains('open--')){
                        Header.navFocusable.forEach(item => item.tabIndex = "")
                        Header.subNavFocusable.forEach(item => item.tabIndex = "-1")
                        Header.mainFocusable.forEach(item => {
                            item.trueTabIndex = item.tabIndex;
                            item.tabIndex = "-1";
                        })
                        Header.footerFocusable.forEach(item => item.tabIndex = "-1")
                    } else {
                        Header.navFocusable.forEach(item => item.tabIndex = "-1")
                        Header.mainFocusable.forEach(item => item.tabIndex = item.trueTabIndex)
                        Header.footerFocusable.forEach(item => item.tabIndex = "")
                    }

                    Header.menus.forEach(menu => menu.classList.remove("subopen--"))
                    Header.mainmenu.classList.remove("subopen--");
                });
            }

            Header.buttons.forEach(btn => {
                btn.subMenu = btn.parentElement.querySelector(".subMenu__btnback");
                if (btn.subMenu) {
                    btn.subMenu.addEventListener("click", e => Header.hideSubMenu(btn, e))
                }
                btn.addEventListener("mouseenter", e => Header.showSubMenu(btn, e));
                btn.addEventListener("click", e => Header.showSubMenu(btn, e))
            })

            Header.mainButtons.forEach(btn => {
                btn.addEventListener("mouseenter", e => {
                    if (!btn.parentElement.classList.contains('menu-item-has-children')) {
                        Header.hideAllSubMenus();
                    }
                });
            });
        }
    },

    showSubMenu: function (btn, e) {
        e.preventDefault();

        if (Header.currentSubMenu === btn.parentElement) return;

        Header.hideAllSubMenus();

        btn.parentElement.classList.add("subopen--");
        Header.mainmenu.classList.add("subopen--");
        btn.parentElement.querySelectorAll(".header__box a").forEach(item => item.tabIndex = "");

        Header.currentSubMenu = btn.parentElement;
    },

    hideSubMenu: function (btn, e) {
        e.preventDefault();
        btn.parentElement.classList.remove("subopen--");
        Header.mainmenu.classList.remove("subopen--");
        Header.subNavFocusable.forEach(item => item.tabIndex = "-1")

        Header.currentSubMenu = null;
    },

    hideAllSubMenus: function () {
        Header.menus.forEach(menu => menu.classList.remove("subopen--"));
        Header.mainmenu.classList.remove("subopen--");
        Header.subNavFocusable.forEach(item => item.tabIndex = "-1");

        Header.currentSubMenu = null;
    }
};

export default Header;
