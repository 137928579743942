'use strict';

const FadeSlideInChildren = {
    selectors: null,
    init: function (elem, index) {
        [...elem.children].forEach(child => {
            child.index = index;
            child.classList.add("anim-delay" + index++)

            child.addEventListener("transitionend", e => {
                if (child.timeOut) {
                    clearTimeout(child.timeOut);
                }

                child.timeOut = setTimeout(() => {
                    child.classList.remove("anim-delay" + child.index)
                }, 1000);
            })
        })

        return index;
    }
};

export default FadeSlideInChildren;
