'use strict';

const Footer = {
    el: null,
    toggle: null,
    init: function () {
        Footer.el = document.querySelector('.footer');
        Footer.moveTop = Footer.el.querySelector(".footer__tools__moveTop");

        if (Footer.el && Footer.moveTop) {
            Footer.moveTop.addEventListener("click", e => document.documentElement.scrollTop = 0)
        }
    }
};

export default Footer;
