'use strict';

const InteractiveImage = {
    init: function () {
        let interactivesImage = document.querySelectorAll(".f-interactiveimage");
        interactivesImage.forEach(f => {
            f.box = f.querySelector(".f-interactiveimage__image__liste");
            f.onglets = f.querySelectorAll(".f-interactiveimage__tabs__button");

            if (f.onglets.length > 0) {
                f.panels = f.querySelectorAll(".f-interactiveimage__image__item");
                f.currentIndex = 0;

                f.onglets.forEach((onglet, i) => onglet.addEventListener("mousemove", () => setTimeout(() => InteractiveImage.changeOnglet(f, onglet, i), 10)));
                f.onglets.forEach((onglet, i) => onglet.addEventListener("focus", () => setTimeout(() => InteractiveImage.changeOnglet(f, onglet, i), 10)));
                f.onglets.forEach((onglet, i) => onglet.addEventListener("click", e => InteractiveImage.manageClick(e, onglet)));
            }
        })
    },

    manageClick: function (e, onglet) {
        if (window.innerWidth < 841 && !onglet.classList.contains("selected2")) {
            e.preventDefault();
            onglet.classList.add("selected2");
        }
    },

    changeOnglet: function (currentTab, currentOnglet, currentIndex) {
        if (!currentOnglet.classList.contains("selected")) {
            currentTab.onglets.forEach(onglet => onglet.classList.remove("selected"));
            currentOnglet.classList.add("selected");

            currentTab.panels.forEach((panel, index) => {
                panel.classList.remove("before");
                panel.classList.remove("after")
                panel.classList.remove("selected");
                panel.classList.remove("selected2");

                if (index === currentIndex) {
                    panel.classList.add("selected");
                } else if (index < currentIndex) {
                    panel.classList.add("before");
                } else {
                    panel.classList.add("after");
                }
            });

            currentTab.currentIndex = currentIndex;
        }
    },
};

export default InteractiveImage;