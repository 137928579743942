'use strict';

const ScrollManager = {
    flexibles: null,
    init: function (flexibles) {
        ScrollManager.flexibles = flexibles;
        document.addEventListener("scroll", ScrollManager.checkScroll);
        ScrollManager.checkScroll();
    },

    checkScroll: function(){
        let wHeight = window.innerHeight;

        ScrollManager.flexibles.forEach(flex => {
            let infos = flex.getBoundingClientRect();
            let top = infos.top;
            
            if(top < wHeight * 0.75){
                flex.classList.add("doAnim");
            }
        })
    }
};

export default ScrollManager;
