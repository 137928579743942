'use strict';

const Wysiwyg = {
    els: null,
    toggle: null,
    init: function () {
        Wysiwyg.els = document.querySelectorAll('.c-wysiwyg .c-button');

        Wysiwyg.els.forEach(el => {
            if (el.parentElement.tagName === "P" && el.children.length === 0) {
                el.parentElement.classList.add("c-wysiwyg__cta");
                el.innerHTML = "<span>" + el.innerHTML + "</span>";
                el.classList.remove("c-button");
                if(!el.classList.contains("arrow--")){
                    el.classList.add("--noarrow");
                }
            }
        })
    }
};

export default Wysiwyg;
