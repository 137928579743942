'use strict';

const Tab = {
    init: function () {
        let tabs = document.querySelectorAll(".f-tab");
        tabs.forEach(tab => {
            tab.box = tab.querySelector(".f-tab__list__box");

            tab.barreMobile = document.createElement("div");
            tab.barreMobile.classList.add("f-tab__list__barre");
            tab.barreMobile.classList.add("fade-slide-in");
            tab.barreMobile.classList.add("f-tab__list__barre--mobile");
            tab.box.appendChild(tab.barreMobile);

            tab.barreDesktop = document.createElement("div");
            tab.barreDesktop.classList.add("f-tab__list__barre");
            tab.barreDesktop.classList.add("fade-slide-in");
            tab.barreDesktop.classList.add("f-tab__list__barre--desktop");
            tab.box.appendChild(tab.barreDesktop);

            tab.onglets = tab.querySelectorAll(".f-tab__list__rubrique-item");

            if (tab.onglets.length > 0) {
                tab.panels = tab.querySelectorAll(".f-tab__list__content-item");
                tab.currentIndex = 0;

                Tab.setBarre(tab, tab.onglets[0])

                tab.onglets.forEach((onglet, i) => onglet.addEventListener("click", () => Tab.changeOnglet(tab, onglet, i)));
            }
        })
    },

    changeOnglet: function (currentTab, currentOnglet, currentIndex) {
        if (!currentOnglet.classList.contains("selected")) {
            currentTab.onglets.forEach(onglet => onglet.classList.remove("selected"));
            currentOnglet.classList.add("selected");

            Tab.setBarre(currentTab, currentOnglet)

            currentTab.panels.forEach((panel, index) => {
                panel.classList.remove("before");
                panel.classList.remove("after")
                panel.classList.remove("selected");

                if (index === currentIndex) {
                    panel.classList.add("selected");
                } else if (index < currentIndex) {
                    panel.classList.add("before");
                } else {
                    panel.classList.add("after");
                }
            });

            currentTab.currentIndex = currentIndex;
        }
    },

    setBarre(currentTab, currentOnglet) {
        let infos = currentOnglet.getBoundingClientRect();
        currentTab.barreMobile.style.width = infos.width + "px";
        currentTab.barreMobile.style.left = currentOnglet.offsetLeft + "px";

        currentTab.barreDesktop.style.height = infos.height + "px";
        currentTab.barreDesktop.style.top = currentOnglet.offsetTop + "px";
    }
};

export default Tab;