'use strict';

const Accordion = {
    init: function () {
        const toggleButtons = document.querySelectorAll('.toggle-button');
        toggleButtons.forEach(button => {
            button.addEventListener('click', () => {
                toggleButtons.forEach(btn => {
                    if(btn === button){
                        btn.parentElement.classList.toggle('f-accordion__list-item--open');
                    } else {
                        btn.parentElement.classList.remove('f-accordion__list-item--open');
                    }
                })
            });

            button.addEventListener('focus', () => Accordion.manageFocus(button, true));
            button.addEventListener('mouseenter', () => Accordion.manageFocus(button, true));
            button.addEventListener('blur', () => Accordion.manageFocus(button, false));
            button.addEventListener('mouseleave', () => Accordion.manageFocus(button, false));
        });
    },

    manageFocus: function(button, isFocus){
        button.parentElement.classList[isFocus ? "add" : "remove"]("f-accordion__list-item--focus");
    }
};

export default Accordion;