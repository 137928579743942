'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';


const SliderSingle = {
    els: null,
    init: function () {
        SliderSingle.els = document.querySelectorAll('.f-slider.single--');
        if (SliderSingle.els && SliderSingle.els.length > 0) {
            SliderSingle.els.forEach(el => {
                SliderSingle.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        let navigation = null;
        let scrollbar = null;

        if (slider.dataset.navigation === "arrows") {
            if(el.classList.contains('textImgSlider--')){
                swiper = new Swiper(slider, {
                    modules: [Navigation, A11y],
                    a11y: true,
                    spaceBetween: 30,
                    slidesPerView: 1,
                    loop: false,
                    speed: 600,
                    autoHeight:false,
                    navigation: {
                        prevEl: ".f-slider__navigation-prev",
                        nextEl: ".f-slider__navigation-next",
                    }
                });
            } else {
                swiper = new Swiper(slider, {
                    modules: [Navigation, A11y],
                    a11y: true,
                    spaceBetween: 30,
                    slidesPerView: 1,
                    loop: false,
                    speed: 600,
                    autoHeight:true,
                    navigation: {
                        prevEl: ".f-slider__navigation-prev",
                        nextEl: ".f-slider__navigation-next",
                    }
                });
            }
        }
        if (slider.dataset.navigation === "scrollbar") {

            swiper = new Swiper(slider, {
                modules: [Scrollbar, A11y],
                a11y: true,
                spaceBetween: 30,
                slidesPerView: 1,
                loop: false,
                speed: 600,
                autoHeight:false,
                scrollbar: {
                    el: ".f-slider__scrollbar",
                    hide: false,
                    draggable: true
                }
            });
        }

    },
};

export default SliderSingle;