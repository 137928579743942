'use strict';

const ImagePath = {
    init: function (elem, index) {
        elem.index = index;
        elem.classList.add("anim-delay" + index++)

        elem.addEventListener("transitionend", e => {
            if (elem.timeOut) {
                clearTimeout(elem.timeOut);
            }

            elem.timeOut = setTimeout(() => {
                elem.classList.remove("anim-delay" + elem.index)
            }, 1000);
        })
        
        return index;
    },
};

export default ImagePath;
