'use strict';

import Select from "./Select";

const Offices = {
    els: null,
    data: null,
    NB_ELEM_PER_PAGE: 4,
    init: function () {
        Offices.els = document.querySelectorAll(".f-offices");
        Offices.url = api?.agencies;
        Offices.divisions = api?.divisions.length ? api?.divisions.split(',').map( el => el.replace(' ', '')) : null
                
        if (Offices.url && Offices.els.length > 0) {

            fetch(Offices.url, {
                headers: {
                    "partner-key": process.env.AGENCY_KEY,
                }
            })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    
                    let data = json; 

                    if(Offices.divisions){
                        let ctn = json.content.filter( el => Object.keys(el.division).some(el => Offices.divisions.includes(el)))
                        data = { ...json, content: ctn}
                    }
                    
                    Offices.data = data;
                    
                    Offices.els.forEach(off => {
                        off.agency = off.dataset.agency;
                        off.data = JSON.parse(JSON.stringify(data));
                        off.data.content = off.data.content.filter(c => !off.agency || c.entity[off.agency])

                        off.currentPage = 0;
                        off.fullbox = off.querySelector(".f-offices__col--result");
                        off.box = off.querySelector(".f-offices__liste");
                        off.filter = {};

                        off.selects = off.querySelectorAll(".filter");
                        off.selects.forEach(select => {
                            Offices.addOptions(off, select);
                            Select.update(select, select.closest(".f-offices__field"));
                        })

                        off.search = off.querySelector(".f-offices__field--search input");
                        if (off.search) {
                            Offices.addTextSearch(off, off.search);
                        }

                        off.loadMore = off.querySelector(".f-offices__loadmore");
                        if (off.loadMore) {
                            off.loadMore.addEventListener("click", e => {
                                off.currentPage++;
                                Offices.showResult(off);
                            })
                        }

                        off.current = off.querySelector(".f-offices__current");
                        off.total = off.querySelector(".f-offices__total");

                        Offices.showResult(off);
                    })
                }).catch(e => {
                    console.log(e);
                    setTimeout(() => {
                        document.querySelectorAll(".f-offices__col--result").forEach(b => b.classList.add("doAnim"))
                    }, 10);
                });
        }
    },

    addOptions: function (off, select) {
        let name = select.name;

        if (off.data.filters[name]) {
            Offices.updateFilter(off, name, "all");

            for (var key in off.data.filters[name]) {
                let option = document.createElement("option");
                option.value = key;
                option.innerHTML = off.data.filters[name][key];
                select.appendChild(option);
            }

            select.addEventListener("change", e => {
                Offices.updateFilter(off, name, e.target.value);
                Offices.showResult(off);
            })
        }
    },

    addTextSearch: function (off, input) {
        Offices.updateFilter(off, "text", "");

        input.addEventListener("keyup", e => {
            Offices.updateFilter(off, "text", input.value);
            Offices.showResult(off);
        });
    },

    resetOptions: function (off) {
        off.selects.forEach(select => [...select.options].filter(option => option.value !== "all").forEach(option => option.disabled = true))
    },

    updateOptions: function (off) {
        Offices.resetOptions(off);

        off.selects.forEach(select => {
            let name = select.name;

            off.resultsFilters[name].forEach(result => {
                let filters = result[name];

                for (let key in filters) {
                    let option = select.querySelector("[value='" + key + "'");
                    if (option) {
                        option.disabled = false;
                    }
                }
            });
        });

        off.selects.forEach(select => Select.updateDisabled(select));
    },

    updateFilter: function (off, key, value) {
        off.filter[key] = value;
        off.currentPage = 0;
    },

    showResult: function (off) {

        if (Object.keys(off.data.content).length > 0) {

            if (off.currentPage === 0) {
                off.box.innerHTML = "";
            }

            if (off.ul && off.ul.children.length > 0) {
                [...off.ul.children].forEach(child => child.classList.add("forcedAnim"))
            }

            off.fullbox.classList.remove("doAnim");

            off.results = [];

            for (var key in off.data.content) {
                let isValid = true;

                for (let key2 in off.filter) {
                    if (key2 === "text") {
                        isValid = isValid && off.data.content[key].title.toLowerCase().indexOf(off.filter[key2].toLowerCase()) > -1;
                    } else {
                        isValid = isValid && (off.filter[key2] === "all" || off.data.content[key][key2][off.filter[key2]]);
                    }
                }

                if (isValid) {
                    off.results.push(off.data.content[key]);
                }
            }

            off.resultsFilters = {};

            for (let key3 in off.filter) {
                if (key3 !== "text") {
                    off.resultsFilters[key3] = [];

                    for (var key in off.data.content) {
                        let isValid = true;

                        for (let key2 in off.filter) {
                            if (key2 !== "text" && key2 !== key3) {
                                isValid = isValid && (off.filter[key2] === "all" || off.data.content[key][key2][off.filter[key2]]);
                            }
                        }

                        if (isValid) {
                            off.resultsFilters[key3].push(off.data.content[key]);
                        }
                    }
                }
            }

            let filteredData = off.results.filter((item, index) => index >= off.currentPage * Offices.NB_ELEM_PER_PAGE && index < ((off.currentPage + 1) * Offices.NB_ELEM_PER_PAGE))

            if (filteredData.length >= 0) {
                if (off.currentPage === 0) {
                    off.ul = document.createElement("ul");
                    off.ul.classList.add("f-offices__list");
                    off.box.appendChild(off.ul);
                }

                let index = 0;

                filteredData.forEach(f => {
                    let infos = Offices.getItem(index, f);
                    index = infos[1];
                    off.ul.innerHTML += infos[0];
                })
            }

            if (off.current && off.total) {
                let totalElements = off.results.length;
                let currentElements = off.currentPage * Offices.NB_ELEM_PER_PAGE + filteredData.length

                if (currentElements >= totalElements) {
                    currentElements = totalElements;
                    off.loadMore.classList.add("hidden");
                } else {
                    off.loadMore.classList.remove("hidden");
                }

                off.current.innerHTML = currentElements;
                off.total.innerHTML = totalElements;
            }

            Offices.updateOptions(off);

            setTimeout(() => {
                off.fullbox.classList.add("doAnim");
            }, 10);
        }
    },

    getItem: function (index, data) {

        let html = "";

        if (data.title) {
            html = `
                <li class="f-offices__item border-bottom-scale anim-delay${++index}">
                    <p class="f-offices__item__titre fade-slide-in anim-delay${++index}">${data.website ? "<a title='Go to the " + data.title + " website (new window)' target='_blank' href='" + data.website + "'>" + data.title + "</a>" : data.title}</p>
                    <div class="f-offices__item__cols">
                        <div class="f-offices__item__adresse fade-slide-in anim-delay${++index}">
                            ${data.address ? data.address + "<br>" : ""}
                            ${data.address_2 ? data.address_2 + "<br>" : ""}
                            ${data.address_3 ? data.address_3 + "<br>" : ""}
                            ${data.zip_code ? data.zip_code : ""}${data.zip_code && !data.city ? "<br>" : ""}${data.zip_code && data.city ? "," : ""} ${data.city ? data.city + "<br>" : ""}
                            ${data.country[0] ? data.country[0] + "<br>" : ""}
                        </div>
                        <div class="f-offices__item__contact fade-slide-in anim-delay${++index}">
                            ${data.phone ? data.phone + "<br>" : ""}
                            ${data.contact_email ? "<a href='mailto:" + data.contact_email + "'>" + data.contact_email + "</a>" + "<br>" : ""}
                        </div>
                    </div>
                </li>
            `
        }

        return [html, index]
    }
};

export default Offices;
