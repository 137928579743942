'use strict';

const SplitTexte = {

    init: function (txt, index) {
        txt.index = index;

        txt.vitesse = txt.dataset.vitesse ? txt.dataset.vitesse : 0.3;
        txt.innerHTML = txt.innerHTML.split("-").join("&#8209;")
        let save = txt.innerHTML;
        let data = txt.innerHTML.trim().split(" ");
        let tampon = "";
        txt.paragraphes = [];
        let height = -1;
        let lineHeight = 0;
        txt.innerHTML = "";

        for (let i = 0; i < data.length; i++) {
            txt.innerHTML += data[i] + " ";
            let currentHeight = txt.getBoundingClientRect().height;

            if (height === -1) {
                lineHeight = currentHeight;
                height = currentHeight;
            }

            if (height != currentHeight) {
                height = currentHeight;
                txt.paragraphes.push(tampon.substring(0, tampon.length - 1));
                tampon = "";
            }
            tampon += data[i] + " ";
        }

        txt.paragraphes.push(tampon.substring(0, tampon.length - 1));

        txt.innerHTML = "";
        txt.paragraphes.forEach((p, i) => {
            let para = document.createElement("p");
            para.innerHTML = "<span style='transform:translateY(" + lineHeight + "px)' class='anim-delay" + index++ + "'>" + p + "</span>";
            para.classList.add("paragraphe")
            para.style.lineHeight = lineHeight + "px";
            txt.appendChild(para);
        })


        txt.addEventListener("transitionend", e => {

            if (e.propertyName === "transform") {
                if (txt.timeOut) {
                    clearTimeout(txt.timeOut);
                }
                txt.timeOut = setTimeout(() => {
                    txt.innerHTML = save;
                }, 300);
            }

        })

        return index;
    }
};

export default SplitTexte;
