'use strict';

import customSelect from 'custom-select';
import "../../../node_modules/custom-select/build/custom-select.css";

const Select = {
    els: null,
    init: function () {
        Select.els = document.querySelectorAll('select.custom');

        Select.els.forEach(sel => {
            customSelect(sel);
            sel.customSelect.panel.children[0].innerHTML = "All";
        })
    },

    update: function (sel, parent) {
        if (!sel.customSelect) {
            customSelect(sel);
            sel.customSelect.panel.children[0].innerHTML = "All";

            sel.customSelect.container.addEventListener('custom-select:open', e => parent.classList.add("is-open"));
            sel.customSelect.container.addEventListener('custom-select:close', e => parent.classList.remove("is-open"));
        }
    },

    updateDisabled: function (sel) {
        if (sel.customSelect && sel.customSelect.panel?.children) {
            [...sel.options].forEach((opt, index) => {
                if (opt.disabled) {
                    sel.customSelect.panel.children[index].classList.add("disabled");
                } else {
                    sel.customSelect.panel.children[index].classList.remove("disabled");
                }
            })

        }
    }
};

export default Select;
