'use strict';

const FadeSlideIn = {
    selectors: null,
    init: function (elem, index) {
        elem.index = index;
        elem.classList.add("anim-delay" + index++)

        elem.addEventListener("transitionend", e => {
            if (elem.timeOut) {
                clearTimeout(elem.timeOut);
            }

            elem.timeOut = setTimeout(() => {
                elem.classList.remove("anim-delay" + elem.index);
                elem.classList.remove("fade-slide-in");
            }, 1000);
        })

        return index;
    }
};

export default FadeSlideIn;
