'use strict';

// core version + modules:
import Swiper, { Navigation, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

const SliderMulti = {
    els: null,
    init: function () {
        SliderMulti.els = document.querySelectorAll('.f-slider.multi--');
        if (SliderMulti.els && SliderMulti.els.length > 0) {
            SliderMulti.els.forEach(el => {
                SliderMulti.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        slider.swiper = null;


        let modules = "";
        let navigation = null;
        let scrollbar = null;

        if (slider.dataset.navigation === "arrows") {
            slider.swiper = new Swiper(slider, {
                modules: [Navigation, A11y],
                a11y: true,
                spaceBetween: 16,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight: false,
                navigation: {
                    prevEl: ".f-slider__navigation-prev",
                    nextEl: ".f-slider__navigation-next",
                },
                breakpoints: {
                    841: {
                        spaceBetween: 24,
                    },
                    1320: {
                        spaceBetween: 32,
                    }
                }, //swiper-button-disabled
                on: {
                    init: function () {
                        if(slider.swiper.slides.length <= 3){
                            slider.classList.add("threeorless");
                            console.log(slider);
                        } else {
                            slider.classList.remove("threeorless")
                            console.log(slider);
                        }
                        // Vérifie si le nombre de slides est supérieur au nombre de slides visibles
                        if (this.navigation.nextEl.classList.contains("swiper-button-disabled") && this.navigation.prevEl.classList.contains("swiper-button-disabled")) {
                            // Masque les flèches si pas assez de slides
                            this.navigation.nextEl.style.visibility = 'hidden';
                            this.navigation.prevEl.style.visibility = 'hidden';
                        }
                    },
                    resize: function () {
                        // Vérifie de nouveau si on redimensionne la fenêtre
                        if (this.navigation.nextEl.classList.contains("swiper-button-disabled") && this.navigation.prevEl.classList.contains("swiper-button-disabled")) {
                            this.navigation.nextEl.style.visibility = 'hidden';
                            this.navigation.prevEl.style.visibility = 'hidden';
                        } else {
                            this.navigation.nextEl.style.visibility = 'visible';
                            this.navigation.prevEl.style.visibility = 'visible';
                        }
                    }
                }
            });
        }
        if (slider.dataset.navigation === "scrollbar") {
            slider.swiper = new Swiper(slider, {
                modules: [Scrollbar, Avisible1y],
                a11y: true,
                spaceBetween: 16,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight: false,
                scrollbar: {
                    el: ".f-slider__scrollbar",
                    hide: false,
                    draggable: true
                }
            });
        }

    },
};

export default SliderMulti;